




































































import {
  defineComponent,
} from '@nuxtjs/composition-api';
import { SkeletonLoader } from '~/components';

export default defineComponent({
  name: 'ProductSkeleton',
  components: {
    SkeletonLoader,
  },
});
